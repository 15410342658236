import _ from 'lodash';
import qs from 'qs';
import uuid from 'uuid/v4';
import { getName as getCountryFromCode, overwrite } from 'country-list';
import cleanDeep from 'clean-deep';
import states from 'us-state-codes';
import API from '../api';
import { scrollToTop } from '../helper';
import {
  CLEAR_LIFEGROUPS_LIST,
  GOTO_UISTATE,
  REMOVE_CAMPUS,
  REPORT_FETCH_PROGRESS,
  RESET_CAMPUSES,
  RESET_CHURCH_ONLINE_LOCATION,
  RESET_DAYS,
  RESET_OTHER_CATEGORIES,
  SET_AGE,
  SET_CAMPUS,
  SET_CATEGORY,
  SET_CHURCH_ONLINE_LOCATION,
  SET_CHURCH_ONLINE_LOCATIONS,
  SET_DAY,
  SET_GROUP_TYPE,
  SET_KEYWORDS,
  SET_KIDS_WELCOME,
  SET_LIFEGROUPS_LIST,
  SET_LOCATIONS,
  SET_MEET_ONLINE,
  SET_MOBILE_STATUS,
  SET_PARAMS,
  SET_USER,
  TOGGLE_CATEGORY_CHECKBOX_GROUP,
  TOGGLE_DAY_CHECKBOX_GROUP,
  TOGGLE_GROUP_TYPE_CHECKBOX,
  TOGGLE_SEARCH_TRAY,
  UISTATE_SEARCH_PAGE,
} from '../types';

overwrite([
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'BO',
    name: 'Bolivia',
  },
]);

export const gotoState = (state) => {
  return {
    payload: state,
    type: GOTO_UISTATE,
  };
};

function syncUrlWithSearch({
  age,
  categories,
  filteredList,
  groupTypes,
  days,
  keywords,
  churchOnlineLocation,
  kidsWelcome,
  meetOnline,
}) {
  // Handle different params depending if church online is selected
  const locationParam = filteredList?.includes('int')
    ? { group_location: churchOnlineLocation?.slug }
    : null;
  const showKidsWelcome = kidsWelcome === 'true' ? true : null;
  const showMeetOnline = meetOnline === 'true' ? true : null;
  const campusCodeForOthers =
    filteredList.length === 0 ? '' : filteredList.sort().join(',');
  const cleanedParams = cleanDeep({
    age,
    campus: filteredList?.includes('all') ? 'all' : campusCodeForOthers,
    categories,
    days,
    groupTypes,
    keywords,
    ...locationParam,
    kidsWelcome: showKidsWelcome,
    meetOnline: showMeetOnline,
  });
  const search = qs.stringify(cleanedParams, { encode: false });
  window.history.replaceState(
    null,
    null,
    `${window.location.pathname}?${search}`,
  );
  // Send the new search string to the parent site
  window.parent.postMessage({ channel: 'lifegroups', data: search }, '*'); // NOSONAR
}

export const toggleDayCheckboxGroup = (value) => (dispatch, getState) => {
  return dispatch({
    payload: !value,
    type: TOGGLE_DAY_CHECKBOX_GROUP,
    user: getState().core.user,
  });
};

export const toggleGroupTypeCheckbox = (value) => (dispatch, getState) => {
  return dispatch({
    payload: !value,
    type: TOGGLE_GROUP_TYPE_CHECKBOX,
    user: getState().core.user,
  });
};

export const toggleCategoryCheckboxGroup = (value) => (dispatch, getState) => {
  return dispatch({
    payload: value,
    type: TOGGLE_CATEGORY_CHECKBOX_GROUP,
    user: getState().core.user,
  });
};

export const toggleSearchTray = (value) => (dispatch, getState) => {
  return dispatch({
    payload: !value,
    type: TOGGLE_SEARCH_TRAY,
    user: getState().core.user,
  });
};

export const deliverLocations = (list) => {
  return {
    payload: list,
    type: SET_LOCATIONS,
  };
};

export const deliverChurchOnlineLocations = (list) => {
  return {
    payload: list,
    type: SET_CHURCH_ONLINE_LOCATIONS,
  };
};

export const setCampus =
  (campus, sendAnalytics = true) =>
  (dispatch, getState) => {
    const campuses = [...getState().core.filteredList];
    const arr = [...campuses, campus];
    return dispatch({
      payload: arr,
      sendAnalytics,
      type: SET_CAMPUS,
      user: getState().core.user,
    });
  };

export const removeCampus =
  (campus, sendAnalytics = true) =>
  (dispatch, getState) => {
    const campuses = [...getState().core.filteredList];
    const arr = campuses.filter((cam) => cam !== campus);
    return dispatch({
      payload: arr,
      sendAnalytics,
      type: REMOVE_CAMPUS,
      user: getState().core.user,
    });
  };

export const resetCampuses = () => (dispatch) => {
  return dispatch({
    payload: ['all'],
    type: RESET_CAMPUSES,
  });
};

export const setChurchOnlineLocation = (location, sendAnalytics = true) => {
  return {
    payload: location,
    sendAnalytics,
    type: SET_CHURCH_ONLINE_LOCATION,
  };
};

export const resetChurchOnlineLocation = () => {
  return {
    type: RESET_CHURCH_ONLINE_LOCATION,
  };
};

export const resetOtherCategories = () => {
  return {
    type: RESET_OTHER_CATEGORIES,
  };
};

export const setParams = (params) => {
  return {
    payload: params,
    type: SET_PARAMS,
  };
};

export const setDay = (value) => (dispatch, getState) => {
  let arr;
  const daysArray = [...getState().core.days];
  if (daysArray?.includes(value)) {
    arr = daysArray.filter((day) => day !== value);
  } else {
    arr = [...daysArray, value];
  }
  return dispatch({
    payload: arr,
    type: SET_DAY,
    user: getState().core.user,
  });
};

export const resetDays = () => {
  return {
    type: RESET_DAYS,
  };
};

export const clearLifegroups = () => {
  return {
    type: CLEAR_LIFEGROUPS_LIST,
  };
};

export const deliverLifegroups = (list, hasMoreItems = true) => {
  return {
    payload: { ...list, hasMoreItems },
    type: SET_LIFEGROUPS_LIST,
  };
};

export const setCategory = (value) => (dispatch, getState) => {
  let arr;
  const categoryArray = [...getState().core.categories];
  if (categoryArray?.includes(value)) {
    arr = categoryArray.filter((day) => day !== value);
  } else {
    arr = [...categoryArray, value];
  }
  return dispatch({
    payload: arr,
    type: SET_CATEGORY,
    user: getState().core.user,
  });
};

export const setGroupType = (value) => (dispatch, getState) => {
  return dispatch({
    payload: value,
    type: SET_GROUP_TYPE,
    user: getState().core.user,
  });
};

export const setKidsWelcome = (value) => (dispatch, getState) => {
  // This is awkward because we need the opposite.
  return dispatch({
    payload: value === 'true' ? 'false' : 'true',
    type: SET_KIDS_WELCOME,
    user: getState().core.user,
  });
};

export const setMeetOnline = (value) => (dispatch, getState) => {
  return dispatch({
    payload: value === 'true' ? 'false' : 'true',
    type: SET_MEET_ONLINE,
    user: getState().core.user,
  });
};

export const setAge = (value) => (dispatch, getState) => {
  return dispatch({
    payload: value,
    type: SET_AGE,
    user: getState().core.user,
  });
};

export const setKeywords = (value) => (dispatch, getState) => {
  return dispatch({
    payload: value,
    type: SET_KEYWORDS,
    user: getState().core.user,
  });
};

export const reportFetchProgress = (progressOfFetch) => {
  return {
    progressOfFetch,
    type: REPORT_FETCH_PROGRESS,
  };
};

export const setMobileStatus = (isMobile) => (dispatch, getState) => {
  return dispatch({
    payload: isMobile,
    type: SET_MOBILE_STATUS,
    user: getState().core.user,
  });
};

export const setUser = (user) => {
  return {
    payload: user,
    type: SET_USER,
  };
};

export const fetchChurchOnlineLocations = () => (dispatch) => {
  return API.get(
    `${process.env.REACT_APP_LIFEGROUPS_API_DOMAIN}v1/church-online-locations`,
  ).then((list) => {
    // Currently the list is also returning "Online Only",
    // so we add a short-circuit to handle that if the name doesn't exist

    let newList = list?.map((item) => ({
      id: uuid(),
      name: getCountryFromCode(item) || item,
      slug: item.toLowerCase(),
    }));

    newList = _.sortBy(newList, [
      (o) => {
        return o.name;
      },
    ]);
    newList = [{ id: 'all', name: 'All Countries', slug: 'all' }, ...newList];

    dispatch(deliverChurchOnlineLocations(newList));
  });
};

export const fetchLifegroupData = () => (dispatch, getState) => {
  const {
    days,
    age,
    churchOnlineLocation,
    categories,
    filteredList,
    groupTypes,
    keywords,
    uiState,
    isMobile,
    kidsWelcome,
    meetOnline,
  } = getState().core;

  // Handle different params depending if church online is selected
  const buildGroupLocationParams = () => {
    let slugValue = churchOnlineLocation?.slug ?? '';
    if (churchOnlineLocation?.slug === 'all') {
      slugValue = '';
    }
    if (filteredList?.includes('int')) {
      return {
        'filter[group_location]': slugValue,
      };
    }
    return {};
  };

  const campusCodeForOthers =
    !filteredList || filteredList.length === 0
      ? 'none'
      : filteredList.join(',');
  let params = {
    'filter[age]': age,
    'filter[campus_code]': filteredList?.includes('all')
      ? ''
      : campusCodeForOthers,
    'filter[community_type]': categories?.join(',') ?? '',
    'filter[day_of_week]': days?.join(',') ?? '',
    'filter[group_type]': groupTypes,
    'filter[keywords]': keywords,
    'filter[kids_welcome]': kidsWelcome === 'false' ? null : true,
    'filter[meet_online]': meetOnline === 'false' ? null : true,
    'page[limit]': 24,
    'page[offset]': 0,
  };

  params = { ...params, ...buildGroupLocationParams() };
  dispatch(reportFetchProgress(true));

  if (uiState === UISTATE_SEARCH_PAGE) {
    syncUrlWithSearch(getState().core);
  }
  if (!isMobile) {
    scrollToTop();
  }
  return API.get(
    `${process.env.REACT_APP_LIFEGROUPS_API_DOMAIN}v1/lifegroups`,
    cleanDeep(params),
  ).then((list) => {
    const hasMore = !!list.links.next;
    dispatch(deliverLifegroups(list, hasMore));
    dispatch(reportFetchProgress(false));
  });
};

export const fetchLocations = () => (dispatch) => {
  dispatch(reportFetchProgress(true));
  return API.get(
    `${process.env.REACT_APP_LIFECHURCH_API_DOMAIN}v3/locations?key=e1d1bd4593b9477eaf2073b3a75f15a7`,
  )
    .then((list) => {
      let newList = list
        .sort((a, b) => {
          if (a.name < b.name) return 1;
          if (a.name > b.name) return -1;
          return 0;
        })
        .filter((item) => item.name !== 'Central Support Office') // We don't want Central
        .map((item) => ({
          id: item.id,
          metro: item.metro_group ? item.metro_group : 'none',
          name: item.name,
          slug: item.slug,
          state: states.getStateNameByStateCode(item.state),
        }));

      newList = [
        { id: 'all', metro: '', name: 'All locations', slug: 'all', state: '' },
        {
          id: 'int',
          metro: '',
          name: 'Life.Church Online',
          slug: 'int',
          state: '',
        },
        ...newList,
      ];
      dispatch(deliverLocations(newList));
    })
    .catch(() => {
      dispatch(reportFetchProgress(false));
    });
};

export const fetchMoreData = (url) => (dispatch, getState) => {
  API.get(url).then((list) => {
    const hasMore = !!list.links.next;
    const oldList = getState().core.lifegroupsList;
    const newList = {
      ...oldList,
      data: [...oldList.data, ...list.data],
      links: list.links,
      meta: list.meta,
    };
    dispatch(deliverLifegroups(newList, hasMore));
  });
};

/**
 * @module AppIndex
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import packageJson from '../package.json';

import App from './components/App';
import allReducers from './reducers';

const container = document.getElementById('lifegroups-search');
const root = createRoot(container);

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY ?? '',
  appVersion: packageJson.version,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
);

if (typeof module.hot !== 'undefined') {
  module.hot.accept(); // eslint-disable-line no-undef
}

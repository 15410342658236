// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { callSegmentTrack, getUser, normalize, truncate } from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import './LgCard.scss';

function LgCard(props) {
  const { lgDomain } = config;

  const dayIsWeekday = (day) => {
    return day !== 'Varies';
  };

  const getHref = () => {
    return props.lgCategory.toLowerCase() === 'local partner'
      ? `${lgDomain}/LocalPartners/${props.lgId}`
      : `${lgDomain}/LifeGroups?Group=${props.lgId}`;
  };

  function handleCardClick() {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        age_range: props.lgAgeRange,
        category: props.lgCategory,
        component: 'LifeGroup Card',
        component_url: null,
        day_of_week: props.lgDay,
        description: props.lgDescription,
        frequency: props.lgFrequency,
        group_id: props.lgId,
        kids: props.lgKidsWelcome,
        label: 'Submit',
        location: props.lgCampusCode,
        logged_in: !!user,
        name: props.lgName,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        time: `${props.lgTime} ${props.lgTimeZone || ''}`.trim(),
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div className="columns column-block" data-testid="lg-card-component">
      <a
        href={getHref()}
        onClick={handleCardClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className={`lg-card border-radius-md lg-card-category-${normalize(
            props.lgCategory,
          )}`}
        >
          <div className="lg-card-side-bar">
            <div className="lg-card-meta">
              <div className="fontSize-body lg-day-of-week">{props.lgDay}</div>
              {dayIsWeekday(props.lgDay) ? (
                <div className="fontSize-xxs">
                  {props.lgTime} {props.lgTimeZone}
                </div>
              ) : null}
              {dayIsWeekday(props.lgDay) ? (
                <div className="fontSize-xxs">{props.lgFrequency}</div>
              ) : null}
            </div>
            <div className="lg-card-meta-seperator"></div>
            <div className="lg-card-info m-b-1"> {props.lgCategory} </div>
            <div className="fontSize-xxs m-b-half lg-card-info-age">
              {props.lgAgeRange}
            </div>
            {props.lgKidsWelcome ? (
              <div className="fontSize-xxs lg-card-info-kids">Kids</div>
            ) : null}
          </div>
          <div className="lg-card-content p-t-1">
            <h2 className="lg-card-name fontSize-body color-black">
              {truncate(props.lgName, 40)}
            </h2>
            <p className="lg-card-campus fontSize-xs">{props.lgCampusName}</p>
            <p className="lg-card-description color-black">
              {props.lgDescription}
            </p>
            <div className="description-fade" />
          </div>
          <span className="more-info-text">More Info</span>
        </div>
      </a>
    </div>
  );
}

LgCard.propTypes = {
  lgAgeRange: PropTypes.string.isRequired,
  lgCampusCode: PropTypes.string.isRequired,
  lgCampusName: PropTypes.string,
  lgCategory: PropTypes.string.isRequired,
  lgDay: PropTypes.string.isRequired,
  lgDescription: PropTypes.string.isRequired,
  lgFrequency: PropTypes.string,
  lgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lgKidsWelcome: PropTypes.bool,
  lgName: PropTypes.string.isRequired,
  lgTime: PropTypes.string,
  lgTimeZone: PropTypes.string,
};

export default LgCard;

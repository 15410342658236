// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { APP_CONFIG } from '../../variables';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, APP_NAME, EVENTS } from '../../types';
import './ChurchOnlineBanner.scss';

const showBannerOrNot = (props) => {
  const lifegroupLinkUrl = `https://www.life.church/webview-app/multistep-forms/serving/?utm_source=web&utm_medium=lifegroup-search-app&opportunity=${APP_CONFIG.servingOpportunityGuid}`;
  const churchonlineLinkUrl =
    'https://lp.life.church/onlinelifegroup/?utm_medium=referral&utm_source=life_church&utm_campaign=co_life_group&utm_content=co_start_life_group';
  const url = props?.slug === 'int' ? churchonlineLinkUrl : lifegroupLinkUrl;
  return (
    <section
      className="announcement backgroundColor-gray5 color-gray"
      data-testid="lg-church-online-banner"
    >
      <div className="row">
        <div className="medium-12 columns">
          <div className="wrapper">
            <p>Take your first step to become a LifeGroup leader today.</p>
            <a
              className="button button-primary button-small m-b-0 m-l-half"
              href={url}
              onClick={(event) => {
                const user = getUser();
                callSegmentTrack({
                  event: EVENTS.buttonAction,
                  properties: {
                    action: ACTIONS.clicked,
                    category: APP_NAME,
                    component: 'Banner',
                    component_url: event?.currentTarget?.getAttribute('href'),
                    label: event?.currentTarget?.textContent,
                    location: 'Banner',
                    logged_in: !!user,
                    preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                    referrer: document?.referrer || null,
                    title: document?.title || '',
                    url: window?.location?.href,
                    user_id:
                      user?.['https://www.life.church/rock_person_alias_id'],
                  },
                });
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Start a LifeGroup
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

showBannerOrNot.propTypes = {
  slug: PropTypes.string,
  user: PropTypes.object,
};

showBannerOrNot.defaultProps = {
  slug: '',
  user: null,
};

function ChurchOnlineBanner(props) {
  return showBannerOrNot(props);
}

export default ChurchOnlineBanner;
